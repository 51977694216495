import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Products from '../../../components/Products';
import ContentImage from '../../../components/ContentImage';
import image_1 from '../../../images/products/permanent-infrared-detector/10_PNG.png';
import image_2 from '../../../images/products/permanent-infrared-detector/8_PNG.png';
import image_s1 from '../../../images/products/permanent-infrared-detector/畫面-即時監測.png';
import image_s2 from '../../../images/products/permanent-infrared-detector/畫面-歷史影像回放.png';
import image_s3 from '../../../images/products/permanent-infrared-detector/畫面-即時監控-1.png';
import image_s4 from '../../../images/products/permanent-infrared-detector/畫面-歷史曲線繪製.png';

const ProductsPage = () => {
  const { t } = useTranslation('products_permanent-infrared-detector');
  return (
    <Products pageTitle={t('title')} description={t('description')}>
      <ContentImage image={image_1} title={t('t1.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t1.blurb')}
        </p>
      </ContentImage>
      <ContentImage reverse={true} image={image_2} title={t('t2.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb')}
        </p>
      </ContentImage>
      <ContentImage image={image_s1} title={t('s1.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t1.blurb')}
        </p>
      </ContentImage>
      <ContentImage reverse={true} image={image_s2} title={t('s2.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb')}
        </p>
      </ContentImage>
      <ContentImage image={image_s3} title={t('s3.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t1.blurb')}
        </p>
      </ContentImage>
      <ContentImage reverse={true} image={image_s4} title={t('s4.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb')}
        </p>
      </ContentImage>
    </Products>
  );
};

export default ProductsPage;
